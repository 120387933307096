<template>
  <div class="view-support">
    <header class="bg-dark d-flex justify-content-between p-2 px-5">
      <div class="text-white d-flex align-items-center">
        IEB
      </div>
      <router-link :to="{ name: 'home' }" class="btn btn-primary"
        >Voltar para o Dashboard</router-link
      >
    </header>
    <div class="container py-2">
      <h3 class="page-title mt-4">Enviar uma solicitação</h3>
      <form @submit.prevent="submit" class="col-lg-5 p-0">
        <form-group
          select
          id="solicitation-type"
          v-model="form.solicitation_type"
          label="Escolha o tipo de solicitação"
          :options="[
            { id: 1, name: 'Sou Produtor(a) ou Coprodutor(a)' },
            { id: 2, name: 'Sou Afiliado(a)' }
          ]"
          :errors="errors.solicitation_type"
        />

        <form-group
          v-model="form.email"
          type="email"
          id="email"
          label="Endereço de e-mail*"
          placeholder="Digite seu e-mail aqui"
          :errors="errors.email"
        />

        <form-group
          v-model="form.about"
          select
          id="about"
          label="Quero falar sobre*"
          placeholder="Selecionar..."
          :options="[
            { id: 1, name: 'Alteração do e-mail na Blitzpay' },
            { id: 2, name: 'Cadastro e alteração de dados pessoais' },
            { id: 3, name: 'Cadastro de conta bancária' }
          ]"
          :errors="errors.about"
        />
        <small class="text-secondary"
          >Escolha a opção que tem mais a ver com a sua solicitação.</small
        >

        <div v-if="form.about == 1" class="mt-1">
          <form-group
            id="document"
            v-model="form.document"
            label="CPF ou CNPJ*"
            placeholder="Informe o CPF ou CNPJ cadastrado"
          />
          <small class="text-secondary"
            >Por motivo de segurança e para agilizar o seu atendimento
            precisamos dessa informação.</small
          >
        </div>
        <div v-if="form.about == 2" class="mt-1">
          <form-group
            id="solicitation_name"
            select
            v-model="form.solicitation_name"
            :options="solicitationNameOptions"
            label="Por favor, especifique a solicitação*"
            placeholder="Selecionar..."
          />
          <small class="text-secondary"
            >Escolha a opção que tem mais a ver com a sua solicitação.</small
          >
        </div>
        <hr />

        <form-group
          v-model="form.product_id"
          id="product_id"
          label="Qual o ID do produto?"
          placeholder="Digite o ID do produto"
          :errors="errors.product_id"
        />

        <!-- Somente Brasil na V1
        <form-group
          v-model="form.country"
          select
          id="country"
          label="Em qual país você mora atualmente?*"
          placeholder="Selecionar..."
          :options="[
            { id: 1, name: 'Brasil' },
            { id: 2, name: 'Irã' }
          ]"
          :errors="errors.country"
        />-->

        <!-- Somente português na V1
        <form-group
          v-model="form.language"
          select
          id="language"
          label="Em qual idioma você gostaria de receber nossa resposta?*"
          placeholder="Selecionar..."
          :options="[
            { id: 1, name: 'Português' }
          ]"
          :errors="errors.about"
        />-->

        <form-group
          v-if="form.about == 1"
          id="new-email"
          type="email"
          v-model="form.new_email"
          label="Para qual e-mail deseja atualizar a conta?*"
          placeholder="Digite o seu novo e-mail"
        />

        <form-group
          id="subject"
          label="Assunto*"
          placeholder="Digite o assunto da mensagem"
          v-model="form.subject"
          :errors="errors.subject"
        />
        <form-group
          id="message"
          textarea
          v-model="form.message"
          type="textarea"
          label="Mensagem*"
          placeholder="Digite sua mensagem aqui"
          :errors="errors.message"
        />

        <small class="label-input-file">Anexo</small>
        <div
          class="d-flex flex-column justify-content-center container-input-files"
          :class="{ hasValue: form.files.length > 0 }"
        >
          <div class="d-flex justify-content-center align-items-center">
            <i class="text-primary icon-upload mr-2"></i>
            <span class="text-secondary">
              <strong class="text-primary">Adicione o arquivo</strong> ou
              arraste para cá</span
            >
          </div>

          <input
            id="file"
            type="file"
            class="input-file"
            @change="fileChange($event.target.files)"
          />
          <div class="d-flex justify-content-center">
            <small
              v-for="(file, index) in form.files"
              :key="index"
              class="text-secondary"
            >
              {{ file.name }}
            </small>
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <button
            type="submit"
            class="btn btn-primary btn-fixed-size mt-3"
            :disabled="!isValid"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { formFields } from "@/functions/helpers";
export default {
  data() {
    return {
      ...formFields(
        [
          "solicitation_type",
          "solicitation_name",
          "email",
          "about",
          "product_id",
          "country",
          "language",
          "subject",
          "message",
          "new_email",
          "files"
        ],
        {
          solicitation_type: 1,
          files: []
        }
      ),
      solicitationNameOptions: [
        { id: 1, name: "Alteração de CPF/CNPJ" },
        { id: 2, name: "Alterar país de cadastro" },
        { id: 3, name: "Problemas gerais com os dados de cadastro" }
      ]
    };
  },
  computed: {
    isValid() {
      return (
        this.form.solicitation_type &&
        this.form.email &&
        this.form.about &&
        this.form.subject &&
        this.form.message &&
        (this.form.about == 1
          ? this.form.document && this.form.new_email
          : true) &&
        (this.form.about == 2 ? this.form.solicitation_name : true)
      );
    }
  },
  methods: {
    submit() {
      setTimeout(() => {
        this.$message.success("Solicitação enviada");
      }, 200);
    },
    fileChange(files) {
      if (!files) return;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = parseInt(Math.floor(Math.log(files[0].size) / Math.log(1024)));
      const size =
        Math.round(files[0].size / Math.pow(1024, i), 2) + " " + sizes[i]; // convert to mb and show unit meansurement

      if (size.match("MB") && /([^MB]*)MB/.exec(size)[1] > 5) {
        this.$message.error("O arquivo deve ter no máximo 5 mb");
        return;
      }
      //console.log(this.form.files)
      files.forEach(item => {
        this.form.files.push(item);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.view-support {
  .container-input-files {
    background-color: rgba($color: #9155ec, $alpha: 0.05);
    border-radius: 8px;
    line-height: 15px;
    height: 64px;
    width: 100%;
    border: 1px solid #d2d0d8;

    &.hasValue {
      height: 77px;
    }
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 64px;
    margin-bottom: 10%;
    position: absolute;
    cursor: pointer;
  }

  .icon-upload {
    font-size: 24px;
  }

  .label-input-file {
    color: #595463;
    font-size: 14px;
  }
}
</style>
